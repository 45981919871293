import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=447fb2da&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=447fb2da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447fb2da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicIdentificadorTop: require('/opt/atlassian/pipelines/agent/build/themes/NuevoLeon/components/public/IdentificadorTop.vue').default,PublicLogo: require('/opt/atlassian/pipelines/agent/build/components/public/Logo.vue').default,CompaniesAvatarMenuCompanies: require('/opt/atlassian/pipelines/agent/build/components/Companies/AvatarMenuCompanies.vue').default,PublicAvatarMenu: require('/opt/atlassian/pipelines/agent/build/components/public/AvatarMenu.vue').default,IconsAlertSoporteIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/AlertSoporteIcon.vue').default,ExpedienteConfirmLevelUpDialog: require('/opt/atlassian/pipelines/agent/build/components/expediente/ConfirmLevelUpDialog.vue').default})
